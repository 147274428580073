import { useCallback } from 'react';
import { useToasts } from '@dbs/portinsider-ui-components';
import { Attachment } from '@dbs/portinsider-ui-components/generated/gql';
import { ConfigContext } from '@dbs/portinsider-ui-components/providers/ConfigContext';
import { useFetch } from '@dbs/portinsider-ui-components/hooks/useFetch';

export const useDeleteCommunityAttachment = () => {
  const { fetch, loading } = useFetch();
  const { pushToast } = useToasts();
  const config = ConfigContext.useContainer();

  const deleteFile = useCallback(
    async (attachment: Attachment) => {
      try {
        await fetch(`${config.aws.attachments.gateway}/community/attachments/${attachment.id}`, {
          method: 'DELETE',
        });
      } catch (error) {
        pushToast({ variables: { toast: { message: `The file ${attachment.name} could not be deleted` } } });
        throw new Error('deletion failed');
      }
    },
    [pushToast, config, fetch],
  );

  return { loading, deleteFile };
};
