import React, { HTMLAttributeAnchorTarget } from 'react';
import { UserApplicationRole } from '@dbs/portinsider-ui-components';
import { ConfigContext } from '@dbs/portinsider-ui-components/providers/ConfigContext';
import { Configuration } from '@dbs/portinsider-ui-components/providers/ConfigContext/types';
import { useRoleGuardContext } from '@dbs/portinsider-ui-components/providers/RoleGuardContext';
import { ModuleLink } from '../ModuleLink';
import { StyledList } from './styles';

export const ModuleList = () => {
  const config = ConfigContext.useContainer();
  const { roles } = useRoleGuardContext();
  const navItems = NAV_ITEMS.filter((item) => roles.includes(item.key));

  return (
    <StyledList>
      {navItems.map((item) => (
        <li key={item.key}>
          <ModuleLink label={item.label} target={item.target} href={item.hrefFn(config)} />
        </li>
      ))}
    </StyledList>
  );
};

const BASE_URL = `${window.location.protocol}//${window.location.host}`;
const NAV_ITEMS: NavItemTemplate[] = [
  { key: 'visit-view', label: 'Visit view', hrefFn: () => `${BASE_URL}/visit-view/` },
  { key: 'visit-notification', label: 'Visit notification (port agents)', hrefFn: () => `${BASE_URL}/visit-notification/` },
  { key: 'port-call', label: 'Port call (harbour authority)', hrefFn: () => `${BASE_URL}/port-call/` },
  { key: 'hydrometeo', label: 'Hydrometeo', hrefFn: () => `${BASE_URL}/hydrometeo/` },
  { key: 'map', label: 'Map', target: '_blank', hrefFn: (config) => config.portXchange || '' },
  { key: 'community-admin', label: 'Community admin', hrefFn: () => `${BASE_URL}/community-admin/` },
  { key: 'organization-admin', label: 'Organization admin', hrefFn: () => `${BASE_URL}/organization-admin/` },
  { key: 'configuration', label: 'Configuration', hrefFn: () => `${BASE_URL}/configuration/` },
];

interface NavItemTemplate {
  key: UserApplicationRole;
  label: string;
  target?: HTMLAttributeAnchorTarget;
  hrefFn: (config: Configuration) => string;
}
