import React, { Suspense, useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { CriticalErrorView } from 'components/views/CriticalErrorView';
import { AppContainer } from './styles';
import { useAuth0 } from '@auth0/auth0-react';
import { makeCancelable } from 'app/global/util/makeCancelable';
import { MainView } from 'components/views/MainView';
import { Header } from '../Header';
import { Skeleton } from '../Skeleton';
import { ToastBar } from '@dbs/portinsider-ui-components/components/ToastBar';

export const AppRouter = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated || !loginWithRedirect) {
      return;
    }
    const login = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    const call = makeCancelable(login());

    return () => {
      call.cancel();
    };
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    <Router>
      <AppContainer>
        <Header />
        <ErrorBoundary fallback={CriticalErrorView}>
          <Suspense fallback={<Skeleton />}>
            <Routes>
              <Route path={'/'} element={<MainView />} />
            </Routes>
          </Suspense>
          <ToastBar />
        </ErrorBoundary>
      </AppContainer>
    </Router>
  );
};
