import React from 'react';
import { Container, Content, StyledPaper, Title } from './styles';
import { ModuleList } from 'components/main/ModuleList';
import { DocumentsList } from 'components/main/DocumentsList';
import { FeatureGuard } from '@dbs/portinsider-ui-components';
import { FEATURE_TOGGLES } from 'app/constants';

export const MainView = () => {
  return (
    <Container>
      <StyledPaper>
        <Title>Modules</Title>
        <Content>
          <ModuleList />
        </Content>
      </StyledPaper>
      <FeatureGuard features={[FEATURE_TOGGLES.documents]}>
        <StyledPaper>
          <Title>Documents</Title>
          <Content>
            <DocumentsList />
          </Content>
        </StyledPaper>
      </FeatureGuard>
    </Container>
  );
};
