import { useRef, useEffect } from 'react';

export const useIsMounted = () => {
  const mounted = useRef<boolean>(true);
  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );

  return mounted;
};
