import { gql } from '@apollo/client';
import Attachments from './fragments/attachments.fragment';

export default gql`
  subscription communityAttachmentsUpdatedSubscription(
    $owner: String!
    $application: String!
    $parentType: String!
    $parentId: String!
  ) {
    communityAttachmentsUpdatedSubscription(
      owner: $owner
      application: $application
      parentType: $parentType
      parentId: $parentId
    ) {
      ...Attachments
    }
  }
  ${Attachments}
`;
