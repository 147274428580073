import React, { useState } from 'react';
import { LoadableView } from '@dbs/react-ui-components';
import { DocumentLink } from '../DocumentLink';
import { Container, StyledIconButton, StyledList } from './styles';
import { AttachmentUploadModal } from '@dbs/portinsider-ui-components/components/AttachmentUploadModal';
import { ATTACHMENTS_APPLICATION, ATTACHMENTS_ID, ATTACHMENTS_TYPE } from 'app/attachments/constants';
import { useCommunityAttachments } from 'app/attachments/hooks/useCommunityAttachments';
import { FileIcon } from '@dbs/react-ui-components/components/atoms/icons/FileIcon';
import { useRoleGuardContext } from '@dbs/portinsider-ui-components/providers/RoleGuardContext';
import { useCommunityAttachmentsUpdatedSubscription } from 'app/attachments/hooks/useCommunityAttachmentsUpdatedSubscription';
import { useFileDownload } from '@dbs/portinsider-ui-components/hooks/useFileDownload';
import { useToasts } from '@dbs/portinsider-ui-components/graphql/toasts/useToasts';
import { useDeleteCommunityAttachment } from 'app/attachments/hooks/useDeleteCommunityAttachment';

export const DocumentsList = () => {
  const { data, loading, error } = useCommunityAttachments();
  const { pushToast } = useToasts();
  useCommunityAttachmentsUpdatedSubscription();
  const [uploadActive, setUploadActive] = useState(false);
  const { userHasRole } = useRoleGuardContext();
  const { download } = useFileDownload((err) => pushToast({ variables: { toast: { message: err } } }));
  const { deleteFile } = useDeleteCommunityAttachment();

  return (
    <Container>
      <LoadableView loading={!!loading} error={error ? 'Something went wrong' : ''}>
        <StyledList>
          {data?.attachments.map((attachment) => (
            <DocumentLink
              key={attachment.id}
              label={attachment.name}
              documentStatus={attachment.status}
              onDownload={async () => download(attachment.url, attachment.name, attachment.mimeType)}
              onDelete={userHasRole('community-admin') ? () => deleteFile(attachment) : undefined}
            />
          ))}
          {!data?.attachments?.length && 'There are no documents available'}
        </StyledList>
        {uploadActive && (
          <AttachmentUploadModal
            scope={{
              application: ATTACHMENTS_APPLICATION,
              parentType: ATTACHMENTS_TYPE,
              parentId: ATTACHMENTS_ID,
              community: true,
            }}
            onClose={() => setUploadActive(false)}
            onUploadComplete={() => {
              setUploadActive(false);
            }}
          />
        )}
        {userHasRole('community-admin') && (
          <StyledIconButton simple label="Add document(s)" icon={<FileIcon />} onClick={() => setUploadActive(true)} />
        )}
      </LoadableView>
    </Container>
  );
};
