export interface MakeCancelable {
  promise: Promise<any>;
  cancel: () => void;
}

// Use this to avoid a 'Can't perform a React state update on an unmounted component' warning when using Promisis in async hooks to update component state.
export const makeCancelable = (promise: MakeCancelable['promise']): MakeCancelable => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val: any) => !hasCanceled_ && resolve(val),
      (error: any) => !hasCanceled_ && reject(error),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};
