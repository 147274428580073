import { gql } from '@apollo/client';
import Attachment from './attachment.fragment';

export default gql`
  fragment Attachments on Attachments {
    owner
    application
    parentType
    parentId
    attachments {
      ...Attachment
    }
  }
  ${Attachment}
`;
