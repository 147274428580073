import React from 'react';
import { Container, Content } from './styles';
import { Spinner } from '@dbs/react-ui-components';

export const Skeleton = () => (
  <Container>
    <Content>
      <Spinner />
    </Content>
  </Container>
);
