import { useMemo } from 'react';
import { useIntegratedQuery } from '@dbs/portinsider-ui-components/hooks';

import { Attachments, GetCommunityAttachmentsQuery, GetCommunityAttachmentsQueryVariables } from 'generated/gql';
import GET_ATTACHMENTS from 'app/attachments/graphql/getAttachments';
import { ATTACHMENTS_APPLICATION, ATTACHMENTS_ID, ATTACHMENTS_TYPE } from '../constants';

export const useCommunityAttachments = () => {
  const { data, loading, error } = useIntegratedQuery<GetCommunityAttachmentsQuery, GetCommunityAttachmentsQueryVariables>(
    GET_ATTACHMENTS,
    'attachments',
    {
      variables: {
        input: {
          application: ATTACHMENTS_APPLICATION,
          parentType: ATTACHMENTS_TYPE,
          parentId: ATTACHMENTS_ID,
        },
      },
    },
  );

  return useMemo(
    () => ({
      data: data?.getCommunityAttachments as Attachments,
      loading,
      error,
    }),
    [data, loading, error],
  );
};
