import React, { useMemo } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { GlobalStyle, ThemeProvider, themes } from '@dbs/react-ui-components';

import { ClientSpecifier } from '@dbs/portinsider-ui-components/providers/ApolloClientsProvider';
import { CriticalErrorView } from 'components/views/CriticalErrorView';
import { AppRouter } from 'components/navigation/AppRouter';
import { GlobalContext } from '@dbs/portinsider-ui-components/providers/GlobalContext';

const App = () => {
  const clients = useMemo<ClientSpecifier[]>(
    () => [{ key: 'community' }, { key: 'attachments' }, { key: 'notificationBroker' }],
    [],
  );

  return (
    <ErrorBoundary fallback={CriticalErrorView}>
      <ThemeProvider theme={themes.light}>
        <GlobalStyle />
        <GlobalContext clientSpecifiers={clients}>
          <AppRouter />
        </GlobalContext>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
