import { gql } from '@apollo/client';
import Attachments from './fragments/attachments.fragment';

export default gql`
  query getCommunityAttachments($input: GetAttachmentsInput!) {
    getCommunityAttachments(input: $input) {
      ...Attachments
    }
  }
  ${Attachments}
`;
