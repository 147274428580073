import { useEffect } from 'react';
import { ReconnectingSubscription } from '@dbs/react-ui-components/utilities/ReconnectingSubscription';
import {
  CommunityAttachmentsUpdatedSubscriptionSubscription,
  GetCommunityAttachmentsQuery,
  GetCommunityAttachmentsQueryVariables,
  Subscription,
  CommunityAttachmentsUpdatedSubscriptionSubscriptionVariables,
} from 'generated/gql';
import ATTACHMENTS_UPDATED_SUBSCRIPTION from 'app/attachments/graphql/attachmentsUpdatedSubscription';
import GET_ATTACHMENTS from 'app/attachments/graphql/getAttachments';
import { useIntegratedClient } from '@dbs/portinsider-ui-components/hooks';
import { useOrganization } from '@dbs/portinsider-ui-components/graphql/community/hooks/useOrganization';
import { ATTACHMENTS_APPLICATION, ATTACHMENTS_ID, ATTACHMENTS_TYPE } from '../constants';

export const useCommunityAttachmentsUpdatedSubscription = () => {
  const client = useIntegratedClient('attachments');
  const { data: organization } = useOrganization();

  useEffect(() => {
    if (!organization) return;

    const subscription = new ReconnectingSubscription<CommunityAttachmentsUpdatedSubscriptionSubscription>({
      observe: () => {
        return client.subscribe<Subscription, CommunityAttachmentsUpdatedSubscriptionSubscriptionVariables>({
          query: ATTACHMENTS_UPDATED_SUBSCRIPTION,
          variables: {
            application: ATTACHMENTS_APPLICATION,
            parentType: ATTACHMENTS_TYPE,
            parentId: ATTACHMENTS_ID,
            owner: organization.split('/')[0],
          },
        });
      },
      onData: ({ data }) => {
        if (data?.communityAttachmentsUpdatedSubscription) {
          const { communityAttachmentsUpdatedSubscription } = data;

          client.writeQuery<GetCommunityAttachmentsQuery, GetCommunityAttachmentsQueryVariables>({
            query: GET_ATTACHMENTS,
            variables: {
              input: {
                application: ATTACHMENTS_APPLICATION,
                parentType: ATTACHMENTS_TYPE,
                parentId: ATTACHMENTS_ID,
              },
            },
            data: { getCommunityAttachments: communityAttachmentsUpdatedSubscription },
          });
        }
      },
    });

    subscription.subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [client, organization]);
};
