import { gql } from '@apollo/client';

export default gql`
  fragment Attachment on Attachment {
    id
    name
    mimeType
    url
    status
  }
`;
