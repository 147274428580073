import { screens, Scrollbar } from '@dbs/react-ui-components';
import { Paper } from '@dbs/react-ui-components/components/atoms/Paper';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 960px;
  max-width: 100vw;
  padding: 0 1.0875rem 1.45rem;
`;

export const StyledPaper = styled(Paper)`
  flex: 1;
  margin: 0.5rem;
  padding: 0;
  min-width: 20rem;
  max-width: 30rem;

  ${screens.md} {
    margin: 1rem;
  }

  ${screens.lg} {
    margin: 2rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const Title = styled.h2`
  margin: 0 0 1rem 0;
  padding: 1rem 1rem 0 1rem;
`;

export const Content = styled.div`
  padding: 0 1rem 1rem 1rem;
  overflow-y: auto;
  max-height: 500px;
  height: 100%;

  ${Scrollbar.secondary};
`;
