import React from 'react';
import { createRoot } from 'react-dom/client';
import 'whatwg-fetch';

import App from 'App';

// <React.StrictMode> is disabled as it doesn't play well with Apollo
// https://github.com/apollographql/react-apollo/issues/3644
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
