import React, { useCallback, useState } from 'react';
import { IconButton } from '@dbs/react-ui-components';
import { DownloadIcon } from '@dbs/react-ui-components/components/atoms/icons/DownloadIcon';
import { TrashIcon } from '@dbs/react-ui-components/components/atoms/icons/TrashIcon';
import { InfoIcon } from '@dbs/react-ui-components/components/atoms/icons/InfoIcon';
import { Actions, Row, StatusButton, StyledPopover } from './styles';
import { useIsMounted } from 'app/global/hooks/useIsMounted';
import { Status } from 'generated/gql';

export const DocumentLink = ({ label, documentStatus, onDownload, onDelete }: DocumentLinkProps) => {
  const mounted = useIsMounted();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const doDownload = useCallback(async () => {
    try {
      setIsDownloading(true);
      await onDownload();
    } finally {
      mounted.current && setIsDownloading(false);
    }
  }, [onDownload, mounted]);

  const doDelete = useCallback(async () => {
    try {
      setIsDeleting(true);
      if (onDelete) await onDelete();
    } catch {
      mounted.current && setIsDeleting(false);
    }
  }, [onDelete, mounted]);

  return (
    <Row>
      {label}
      <Actions>
        {documentStatus === Status.Clean && <IconButton loading={isDownloading} icon={<DownloadIcon />} onClick={doDownload} />}
        {documentStatus === Status.PendingVirusCheck && (
          <StyledPopover alignment="right" actionElement={<StatusButton status={documentStatus} icon={<InfoIcon />} />}>
            This attachment is being scanned for viruses.
          </StyledPopover>
        )}
        {documentStatus === Status.Infected && (
          <StyledPopover alignment="right" actionElement={<StatusButton status={documentStatus} icon={<InfoIcon />} />}>
            Our virus scan detected a virus in this attachment, it can not be downloaded
          </StyledPopover>
        )}
        {onDelete && <IconButton loading={isDeleting} icon={<TrashIcon />} onClick={doDelete} />}
      </Actions>
    </Row>
  );
};

export interface DocumentLinkProps {
  label: string;
  documentStatus: Status;
  onDownload: () => Promise<void>;
  onDelete?: () => Promise<void>;
}
