import React from 'react';
import { Logo } from '@dbs/portinsider-ui-components';
import { Container, Content } from './styles';

export const Header = () => {
  return (
    <Container>
      <Content>
        <Logo />
      </Content>
    </Container>
  );
};
