import { IconButton } from '@dbs/react-ui-components/components/atoms/IconButton';
import styled from 'styled-components/macro';

export const StyledList = styled.ul`
  list-type: none;
  padding: 0;
  margin: 0;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledIconButton = styled(IconButton)`
  position: relative;
  justify-content: center;
  margin-top: 1rem;

  > div {
    flex: initial;
  }
`;
