import React from 'react';
import { Anchor, ArrowRight } from './styles';

export const ModuleLink = ({ label, target, href }: ModuleLinkProps) => {
  return (
    <Anchor target={target} href={href}>
      <div>{label}</div>
      <ArrowRight />
    </Anchor>
  );
};

export interface ModuleLinkProps {
  label: string;
  target?: string;
  href: string;
}
